import React from 'react';
// import icon from './icon.png';
// import './App.css';
import Main from './main';

function App() {
  return (
    <Main />
  );
}

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={icon} className="App-logo" alt="icon" />
//         <p>
//           <code>LilyApp.dev</code>
//         </p>
//       </header>
//     </div>
//   );
// }

export default App;
